import './App.scss';

const images = [
    // 'c01.png',
    'c02',
    'c03',
    'c04',
    'c05',
    'c06',
    'c07',
    'c08',
    'c09',
];

function App() {
  return (
    <div className="App">

        <nav class="menu">
            <a href="#video">Video</a>
            <a href="#c02">Chi sono</a>
            <a href="#c04">Punti importanti</a>
            <a href="#c05">Traguardi</a>
            <a href="#c07">Mercatino Green</a>
        </nav>

        <>
            <div id="video" className="diapositiva video">
                <div className="videoContainer">
                    <iframe src="https://player.vimeo.com/video/817800900?h=014187ca2e&autoplay=0&loop=0&autopause=0&portrait=0&byline=0&transparent=0&title=0&playsinline=0" width="640" height="564"
                            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            </div>

            {images.map(image => (
                <div key={image} id={image} className="diapositiva">
                    <img src={`images/${image}.jpg`}/>
                </div>
            ))}
        </>
    </div>
  );
}

export default App;
